import React from 'react'
import "./Services.css"
import Card from '../Card/Card'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Resume from './resume.pdf'
import {themeContext} from '../../Context';
import {useContext} from 'react';
import { motion } from 'framer-motion';


const Services = () => {
    const transition = {duration: 2, type: 'spring'}
    
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="services" id='Services'>
            {/* left side */}
            <div className="awesome">
                <span style={{color: darkMode ? 'white': ''}}>The Awesome</span>
                <span>Services</span>
                <span style={{color: darkMode ? 'white': ''}}>
                    This is just a dummy text to show some contents,<br/>here are more for the continuation of 
                    <br/>
                    the dummy text to show some further contents kono baka.
                </span>
                <a href={Resume} download>
                <button className="button s-button">Download CV</button>
                </a>
                <div className="blur s-blur" style={{background: "#ABF1FF94"}}></div>
            </div>
            {/* right side */}
            {/* 1st */}
            <div className="cards">
                <motion.div 
                    initial={{left: '25rem'}}
                    whileInView= {{left: '14rem'}}
                    transition= {transition}

                    // style={{left:"14rem"}}
                    >
                    <Card emoji = {HeartEmoji}
                        heading = {'Design'}
                        detail = {'Figma, Sketch, Photoshop, Adobe, Adobe XD'}
                    />
                </motion.div>
                {/* 2nd */}
                <motion.div 
                    initial={{left: '-20rem', top:"12rem"}}
                    whileInView= {{left: '-4rem', top:"12rem"}}
                    transition= {transition}

                    // style={{left:"-4rem", top:"12rem"}}
                    >
                    <Card emoji = {Glasses}
                        heading = {'Developer'}
                        detail = {'HTML, CSS, Javascript, React-JS'}
                    />
                </motion.div>
                {/* 3rd */}
                <motion.div 
                    initial={{left: '-1rem', top:"22rem"}}
                    whileInView= {{left: '20rem', top:"22rem"}}
                    transition= {transition}
 
                // style={{left:"20rem", top:"22rem"}}
                >

                    <Card emoji = {Humble}
                        heading = {'UI/UX'}
                        detail = {"Lorem Ipsum is every developer's default content"}
                    />
                </motion.div>
                <div className="blur s-blur1" style={{background: "var(--purple)"}}></div>
                {/* <div className="blur s-blur2" style={{background: "var(--purple)"}}></div> */}
            </div>
        </div>
    )
}

export default Services