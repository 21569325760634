import React from 'react'
import './Intro.css'
import Github from '../../img/github.png'
import LinkedIn from '../../img/linkedin.png'
import Instagram from '../../img/instagram.png'
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import Boy from '../../img/boy.png'
import ThumbUp from '../../img/thumbup.png'
import Crown from '../../img/crown.png'
import GlassesEmoji from '../../img/glassesimoji.png'
import FloatingDiv from '../FloatingDiv/FloatingDiv'
import {themeContext} from '../../Context';
import {useContext} from 'react';
import {motion} from 'framer-motion';


const Intro = () => {
    const transition = {duration: 2, type: 'spring'}

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="intro">
            <div className="i-left">
                <div className="i-name">
                    <span style={{color: darkMode ? 'white': ''}}>Hi, I am</span>
                    <span>Sojok-san</span>
                    <span style={{color: darkMode ? 'white': ''}}>Front-End Developer with high level of experience in web
                        designing and development, producing the best quality work ever.
                    </span>
                </div>

                <button className="button i-button">Hire Me</button>

                <div className="i-icons">
                    <a href='#'>
                        <img src={Github} alt="" />
                    </a>
                    <a href='#'>
                        <img src={LinkedIn} alt="" />
                    </a>
                    <a href='#'>
                        <img src={Instagram} alt="" />
                    </a>
                </div>
            </div>

            <div className="i-right">
                <img src={Vector1} alt="" />
                <img src={Vector2} alt="" />
                <img src={Boy} alt="" />
                
                <motion.img
                initial={{left: '-36%'}}
                whileInView= {{left: '-24%'}}
                transition= {transition}
                 src={GlassesEmoji} alt="" />
                
                <motion.div
                    initial={{top: '-5%',left: '-74%'}}
                    whileInView= {{top: '-5%',left: '64%'}}
                    transition= {transition}

                    // style={{top: '-5%', left: '64%'}}
                    className="floating-div">
                    <FloatingDiv image={Crown} txt1={"Web"} txt2={"Developer"}/>
                </motion.div>
                <motion.div 
                    initial={{top: '9rem',left: '18rem'}}
                    whileInView= {{top: '18rem',left: '-1rem'}}
                    transition= {transition}

                    // style={{top: '18rem', left: '-1rem'}}
                    className="floating-div">
                    <FloatingDiv image={ThumbUp} txt1="Best Design" txt2="Ever"/>
                </motion.div>
                {/* blur div*/}
                <div className="blur" style={{background: 'rgba(236,210,255)'}}>
                </div>
                <div className="blur" style={{background: '#C1F5FF', top: '17rem',
                    width: '21rem', height: '11rem', left: '-9rem'}}>
                </div>
            </div>
        </div>
    )
}

export default Intro